import * as React from "react"

function Hamburger(props) {
  return (
    <svg viewBox='0 0 10 8' className="hamburger-icon">
  <path d='M1 1h8M1 4h 8M1 7h8' 
        stroke='#ffffff' 
        stroke-width='2' 
        stroke-linecap='round'/>
</svg>
  )
}

export default Hamburger
