import React, { useState } from "react"
import { SkipNavContent, SkipNavLink } from "./skip-nav"
import { Header } from "./header"
import { Footer } from "./footer"
import { Seo } from "./seo"

export function Layout({ children }) {
  return (
    <>
      <div>
        <Seo />

        <Header />

        <div className="">
          <SkipNavContent>{children}</SkipNavContent>
        </div>
        <Footer />
        <video
          id="background-video"
          autoPlay
          playsInline
          loop
          muted
          poster="/bg.jpg"
        >
          <source src="/background.mp4" type="video/mp4" />
          <image src="/bg.jpg"></image>
        </video>
      </div>
    </>
  )
}
