import React, {useState, useEffect} from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store-context"
import Logo from "../icons/logo"
import { CartButton } from "./cart-button"
import SearchIcon from "../icons/search"
import Hamburger from "../icons/hamburger"


import { Toast } from "./toast"

import {
  header,
  container,
  logo as logoCss,
  searchButton,
  nav,
} from "./header.module.css"


export function Header() {
  const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
    <div className={container}>
      <header className={header}>


        <div className="	">

          
          <div className="p-3">
            <Link to="/#skip">
              <div className="mx-auto w-full md:w-1/2" >
            <img src={'/logo.png'} className="w-1/6 md:w-1/12 mx-auto" />
            </div>
            </Link>
            <div className="mx-auto text-center subpixel-antialiased">
            <Link className="text-light ml-3" to="/products">SHOP</Link>
            <Link className="text-light ml-3" to="/search">SEARCH</Link>
            <Link className="text-light ml-3" to="/styling">STYLING</Link>
            <Link className="text-light ml-3" to="/about">ABOUT</Link>
            <CartButton quantity={quantity} />
</div>

          </div>

        </div>
      
      </header>
      <Toast show={loading || didJustAddToCart}>
        {!didJustAddToCart ? (
          "Updating…"
        ) : (
          <>
            Added to cart{" "}
            <svg
              width="14"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                fill="#fff"
              />
              <path
                d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                fill="#fff"
              />
              <path
                d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                fill="#fff"
              />
            </svg>
          </>
        )}
      </Toast>
    </div>
  )
}
